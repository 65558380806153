import { Component } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';

@Component({
  templateUrl: './past-assignments.component.html',
  styleUrl: './past-assignments.component.scss',
})
export class PastAssignmentsComponent {
  constructor(private headerStateService: HeaderStateService) {
    this.headerStateService.setDetailHeader(
      'Vergangene Einsätze',
      'Sehe deine letzten Einsätze',
      '/assignments',
    );
  }
}
