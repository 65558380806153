import { Component } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';

@Component({
    templateUrl: './open-assignments.component.html',
    styleUrl: './open-assignments.component.scss',
})
export class OpenAssignmentsComponent {
    constructor(private headerStateService: HeaderStateService) {
        this.headerStateService.setDetailHeader(
            'Offene Einsätze',
            'Sehe anstehende Einsätze',
            '/assignments'
        );
    }
}
