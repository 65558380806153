import { Component } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';

@Component({
    templateUrl: './my-assignments.component.html',
    styleUrl: './my-assignments.component.scss',
})
export class MyAssignmentsComponent {
    constructor(private headerStateService: HeaderStateService) {
        this.headerStateService.setDetailHeader(
            'Meine Einsätze',
            'Übersicht deiner Einsätze',
            '/assignments'
        );
    }
}
