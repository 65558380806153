<div class="container d-flex flex-column gap-3 text-center border-info">
  <a [routerLink]="'my'" class="text-decoration-none text-dark"
    ><div class="shadow p-3 bg-body rounded">
      <div class="d-flex align-items-center justify-content-between">
        <span>
          {{ "assignments.myAssignments.title" | transloco }}
        </span>
        <i class="fa fa-chevron-right text-primary"></i>
      </div></div
  ></a>

  <a [routerLink]="'exchange'" class="text-decoration-none text-dark">
    <div class="shadow p-3 bg-body rounded">
      <div class="d-flex align-items-center justify-content-between">
        <span>{{ "assignments.exchangeAssignments.title" | transloco }}</span>
        <i class="fa fa-chevron-right text-primary"></i>
      </div>
    </div>
  </a>

  <a [routerLink]="'open'" class="text-decoration-none text-dark">
    <div class="shadow p-3 bg-body rounded">
      <div class="d-flex align-items-center justify-content-between">
        <span> {{ "assignments.openAssignments.title" | transloco }}</span>
        <i class="fa fa-chevron-right text-primary"></i>
      </div>
    </div>
  </a>

  <a [routerLink]="'past'" class="text-decoration-none text-dark"
    ><div class="shadow p-3 bg-body rounded">
      <div class="d-flex align-items-center justify-content-between">
        <span> {{ "assignments.pastAssignments.title" | transloco }}</span>
        <i class="fa fa-chevron-right text-primary"></i>
      </div></div
  ></a>
</div>
