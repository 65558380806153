import { Component } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';

@Component({
    templateUrl: './exchange-assignments.component.html',
    styleUrl: './exchange-assignments.component.scss',
})
export class ExchangeAssignmentsComponent {
    constructor(private headerStateService: HeaderStateService) {
        this.headerStateService.setDetailHeader(
            'Einsätze Tauschen',
            'Tausche Einsätze mit anderen TLs',
            '/assignments'
        );
    }
}
