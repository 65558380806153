import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AssignmentRoutingModule } from './assignment-routing.module';
import { OpenAssignmentsComponent } from './open-assignments/open-assignments.component';
import { PastAssignmentsComponent } from './past-assignments/past-assignments.component';
import { ExchangeAssignmentsComponent } from './exchange-assignments/exchange-assignments.component';
import { MyAssignmentsComponent } from './my-assignments/my-assignments.component';
import { AssignmentsLayoutComponent } from './assignments-layout/assignments-layout.component';
import { AssignmentsPageComponent } from './assignments-page/assignments-page.component';

@NgModule({
  imports: [
    NgSelectModule,
    CommonModule,
    SharedModule,
    AssignmentRoutingModule,
  ],
  declarations: [
    AssignmentsLayoutComponent,
    AssignmentsPageComponent,
    ExchangeAssignmentsComponent,
    OpenAssignmentsComponent,
    PastAssignmentsComponent,
    MyAssignmentsComponent,
  ],
  exports: [],
  providers: [],
})
export class AssignmentModule {}
