import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Routes, RouterModule } from '@angular/router'
import { OpenAssignmentsComponent } from './open-assignments/open-assignments.component'
import { MyAssignmentsComponent } from './my-assignments/my-assignments.component'
import { PastAssignmentsComponent } from './past-assignments/past-assignments.component'
import { ExchangeAssignmentsComponent } from './exchange-assignments/exchange-assignments.component'
import { AssignmentsLayoutComponent } from './assignments-layout/assignments-layout.component'
import { AssignmentsPageComponent } from './assignments-page/assignments-page.component'

const routes: Routes = [
    {
        path: '',
        component: AssignmentsLayoutComponent,
        children: [
            {
                path: '',
                component: AssignmentsPageComponent,
            },

            {
                path: 'my',
                component: MyAssignmentsComponent,
            },
            {
                path: 'open',
                component: OpenAssignmentsComponent,
            },
            {
                path: 'exchange',
                component: ExchangeAssignmentsComponent,
            },
            {
                path: 'past',
                component: PastAssignmentsComponent,
            },
        ],
    },
]

@NgModule({
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AssignmentRoutingModule {}
